<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="节点状态" prop="process_node" style="width: 25%">
              <a-select v-model="queryParams.process_node">
                <a-select-option value="10">待处理</a-select-option>
                <a-select-option value="20">待调度员派单</a-select-option>
                <a-select-option value="30">待签到</a-select-option>
                <a-select-option value="40">待执行</a-select-option>
                <a-select-option value="50">申请延期中</a-select-option>
                <a-select-option value="60">申请强关中</a-select-option>
                <a-select-option value="70">待结单</a-select-option>
                <a-select-option value="80">待验收</a-select-option>
                <a-select-option value="90">待评价</a-select-option>
                <a-select-option value="91">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
<!--            <a-button @click="showModal('add')" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="order_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="need_finish_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="process_node" slot-scope="value">
            <a-tag :color="repairOrderStepColor[value]||''">{{repairOrderSteps[value]||''}}</a-tag>
          </span>
          <span slot="order_type" slot-scope="value">
            <span :title="value==0?'报事':'报修'">{{value==0?'报事':'报修'}}</span>
          </span>
          <span slot="order_status" slot-scope="value">
            <span :title="value==3?'拆分':value==2?'异常':'正常'">{{value==3?'拆分':value==2?'异常':'正常'}}</span>
          </span>
          <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
                <a-menu-item :key="'dispatcher-'+record.order_id" v-if="record.process_node===10||record.process_node===20">派单</a-menu-item>
                <a-menu-item :key="'split-'+record.order_id" v-if="record.process_node===10||record.process_node===20">工单拆分</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="spiltModalVisible" :show-type="modalShowType" :detailData="spiltModalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :params="selectUserParams"></select-user>
    <a-modal v-model="orderModalVisible" title="工单详情" :width="650" :centered="true">
      <template slot="footer">
        <a-button @click="orderModalVisible=false">{{'关闭'}}</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" :model="formData" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 500px">
          <a-input :disabled="true" v-model="formData.monitorpointname" placeholder="请选择项目"></a-input>
        </a-form-model-item>
<!--        <a-form-model-item v-if="formData.order_type===1" label="报修类别" prop="is_plan_repair" style="width: 500px">-->
<!--          <a-select :disabled="true" v-model.trim="formData.is_plan_repair">-->
<!--            <a-select-option value="0">普通维修</a-select-option>-->
<!--            <a-select-option value="1">计划性维修</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
        <a-form-model-item v-if="formData.order_type===1" label="报修来源" prop="order_source" style="width: 500px">
          <a-select :disabled="true" v-model.trim="formData.order_source">
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===0" label="报事来源" prop="order_source" style="width: 500px">
          <a-select :disabled="true" v-model.trim="formData.order_source">
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="接报时间" prop="receive_time" style="width: 500px">
          <a-date-picker :disabled="true" v-model.trim="formData.receive_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===0" label="报事人姓名" prop="contacts" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.contacts" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===0" label="报事人电话" prop="contact_information" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.contact_information" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="报修人姓名" prop="contacts" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.contacts" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="报修人电话" prop="contact_information" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.contact_information" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="维修类型" prop="repair_matter_type" style="width: 500px">
          <a-select :disabled="true" v-model.trim="formData.repair_matter_type">
            <a-select-option value="公区维修">公区维修</a-select-option>
            <a-select-option value="专属维修">专属维修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===0" label="报事类型" prop="repair_matter_type" style="width: 500px">
          <a-select :disabled="true" v-model.trim="formData.repair_matter_type">
            <a-select-option value="建议">建议</a-select-option>
            <a-select-option value="问询">问询</a-select-option>
            <a-select-option value="质疑">质疑</a-select-option>
            <a-select-option value="求助">求助</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1&&formData.repair_matter_type==='专属维修'" label="专属维修条目" prop="entry_id" style="width: 500px">
          <a-select :disabled="true" v-model="formData.entry_id" style="width: 333px;" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in exclusiveRepair" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1&&formData.repair_matter_type==='专属维修'" label="维修服务时间" prop="service_time" style="width: 500px">
          <a-input :disabled="true" style="width: 266px;" v-model="formData.service_time"></a-input><a-button type="primary" @click="makeAppointment">预约</a-button>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 500px">
          <a-select :disabled="true" v-model="formData.biginfluence" style="width: 333px;">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="设备名称" prop="device_name" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.device_name" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="设备编号" prop="device_num" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.device_num" style="width: 333px;"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="设备位置" prop="device_address" style="width: 500px">
          <a-input :disabled="true" v-model.trim="formData.device_address" style="width: 333px;"></a-input>
        </a-form-model-item>
        <!--        <a-form-model-item label="是否需要客户评价" prop="customer_evaluation" style="width: 500px">-->
        <!--          <a-select :disabled="showType=='detail'" v-model.trim="formData.customer_evaluation">-->
        <!--            <a-select-option value="是">是</a-select-option>-->
        <!--            <a-select-option value="否">否</a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="计划完成时间" prop="need_finish_time" style="width: 500px">-->
        <!--          <a-date-picker :disabled="true" v-model.trim="formData.need_finish_time" show-time placeholder="请选择日期和时间" style="width: 333px;"></a-date-picker>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item v-if="formData.order_type===0" label="报事内容" prop="content" style="width: 500px">
          <textarea :disabled="true" v-model.trim="formData.content" style="width: 333px;"></textarea>
        </a-form-model-item>
        <a-form-model-item v-if="formData.order_type===1" label="报修内容" prop="content" style="width: 500px">
          <textarea :disabled="true" v-model.trim="formData.content" style="width: 333px;"></textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <appointment :visible.sync="isAppoint" :detailData="appointDetail" @get-appointment="getAppointment"></appointment>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import moment from "moment";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import {getExclusiveRepair, getRepairsOrderListByCondition} from "A/repairsreport";
import {repairOrderStepColor, repairOrderStepList, repairOrderSteps} from "@/json/repairsOrderSteps";
import addOrEditModal from "V/matterAndRepairs/myTodo/spiltModal";
import SelectUser from "V/matterAndRepairs/myTodo/SelectUser";
import {commitWorkflow} from "A/repairsreport";
import {getPointParamsList} from "A/monitoring";
import appointment from "V/matterAndRepairs/repair/appointment.vue";
export default {
  name: "myTodo",
  mixins: [deptselect,pagination],
  components: {
    appointment,
    addOrEditModal,
    SelectUser,
  },
  data(){
    return{
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      bigInfluences:[],
      repairOrderStepList,
      repairOrderSteps,
      repairOrderStepColor,
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      queryParams: {
        monitorpointnum: '',
        monitorpointname: '',
        order_type: null,
        process_node: '',
        starttime: '',
        endtime: '',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '工单类型',
          dataIndex: 'order_type',
          key: 'order_type',
          ellipsis: true,
          scopedSlots: { customRender: 'order_type' },
        },
        {
          title: '报事/报修单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '报事/报修来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '报事/报修类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'device_name',
          key: 'device_name',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          key: 'contacts',
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contact_information',
          key: 'contact_information',
          ellipsis: true,
        },
        {
          title: '接报内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
        },
        // {
        //   title: '计划完成日期',
        //   dataIndex: 'need_finish_time',
        //   key: 'need_finish_time',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'need_finish_time' },
        // },
        {
          title: '工单状态',
          dataIndex: 'order_status',
          key: 'order_status',
          ellipsis: true,
          scopedSlots: { customRender: 'order_status' },
        },
        {
          title: '节点状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          scopedSlots: { customRender: 'process_node' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      formData: {
        monitorpointnum: '',
        monitorpointname: '',
        order_num:'',
        order_type:null,
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        need_finish_time: null,
        receive_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status:'',
        create_account: '',
        order_source:'',
        repair_matter_type:'',
        device_num:'',
        device_name:'',
        device_address:'',
        entry_id:'',
        service_time:'',
        is_plan_repair :'0',
        customer_evaluation:'否',
      },
      orderModalVisible:false,
      spiltModalVisible:false,
      spiltModalDetailData:null,
      selectManagerVisible:false,
      managerUser:null,
      exclusiveRepair:[],

      isAppoint:false,
      appointDetail:{monitorpointnum:''},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "我的待办"
    },
    breadcrumb() {
      const pages = [{name:"报事报修",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
    selectUserParams() {
      return {
        monitorpointnum: ""
      }
    }
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    this.getExclusiveRepairList();
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
    }
  },
  methods: {
    getExclusiveRepairList(){
      getExclusiveRepair({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.exclusiveRepair=res.item
        }
      })
      getPointParamsList({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length){
          for(let i=0;i<21;i++){
            if(res.item[0][i]!=null){
              this.exclusiveRepairTimes.push({key:i,value:res.item[0][i]})
            }
          }
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        // process_person_name:this.userInfo.username,
        process_dispatcher:this.userInfo.useraccount,
        process_node: this.userInfo.rolename==='项目调度员'?20:10,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getRepairsOrderListByCondition(params).then(res=>{
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      this.showModal(type,record)
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    showModal(type,record) {
      this.modalShowType = type
      if(type==='detail'){
        this.formData=record
        this.formData.receive_time=moment(record.receive_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        this.formData.need_finish_time=moment(record.need_finish_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        this.orderModalVisible=true
      }else if(type==='split'){
        this.spiltModalDetailData = record
        this.spiltModalVisible = true
      }else if(type==='dispatcher'){
        this.selectUserParams.monitorpointnum=record.monitorpointnum
        this.selectUserParams.receive_time=record.receive_time
        this.selectUserParams.order_id=record.order_id
        this.selectManagerVisible = true
      }
    },
    getManagerUser(selectedRows) {
      this.managerUser = selectedRows[0];
      let data={
        nextstep: "10",
        order_id: this.managerUser.order_id,
        nextperson:this.managerUser.useraccount,
        nextpersonname:this.managerUser.username
      };
      commitWorkflow(data).then(res=>{
        if(res&&res.returncode==='0'){
          this.getTableData(true)
          this.$message.success("派单成功")
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    makeAppointment(){
      this.isAppoint=true
      this.appointDetail.monitorpointnum=this.formData.monitorpointnum
    },
    getAppointment(val){
      this.formData.service_time=val
    }
  },
}
</script>
<style scoped>

</style>